import axios from "axios";
import qs from "querystring";
import Cookies from "universal-cookie";
import history from "../routes/history";
import Utils from "../utils";
import { comparesolarmarket } from "../utils/microsites-constants";
class Api {
  // constructor() {}

  static callApi = (fieldsData, lead_id) => {
    fieldsData.lead_id = lead_id;
    // send leads to Hubspot from tutor expert - survey-form-new
    if (
      fieldsData.domain === "www.tutorexpert.com.au" ||
      fieldsData.domain === "tutorexpert.com.au"
    ) {
      this.postHubSpotDataoftutorexpert(fieldsData);
    }
    //support with debt leads
    else if (
      fieldsData.domain === "ds.supportwithdebt.com.au" ||
      fieldsData.domain === "www.ds.supportwithdebt.com.au" ||
      fieldsData.domain === "www.debtrelief.supportwithdebt.com.au" ||
      fieldsData.domain === "debtrelief.supportwithdebt.com.au" ||
      fieldsData.domain === "www.creditcounsellors.supportwithdebt.com.au" ||
      fieldsData.domain === "creditcounsellors.supportwithdebt.com.au" ||
      fieldsData.domain === "www.supportwithdebt.com.au" ||
      fieldsData.domain === "supportwithdebt.com.au" ||
      fieldsData.domain === "2022.supportwithdebt.com.au" ||
      fieldsData.domain === "www.2022.supportwithdebt.com.au" ||
      fieldsData.domain === "v22022.supportwithdebt.com.au" ||
      fieldsData.domain === "www.v22022.supportwithdebt.com.au" ||
      fieldsData.domain === "debtrepair.financesolver.co" ||
      fieldsData.domain === "www.debtrepair.financesolver.co"
    ) {
      this.postDebtReliefleads(fieldsData);
    }
    else if (
      fieldsData.domain === "myhealthcompare.com.au" ||
      fieldsData.domain === "www.myhealthcompare.com.au" ||
      fieldsData.domain === "www.adwords1.myhealthcompare.com.au" ||
      fieldsData.domain === "adwords1.myhealthcompare.com.au" ||
      fieldsData.domain === "www.au.myhealthcompare.com.au" ||
      fieldsData.domain === "au.myhealthcompare.com.au" ||
      fieldsData.domain === "www.healthdeal.myhealthcompare.com.au" ||
      fieldsData.domain === "healthdeal.myhealthcompare.com.au" ||
      fieldsData.domain === "www.compare.myhealthcompare.com.au" ||
      fieldsData.domain === "compare.myhealthcompare.com.au" ||
      fieldsData.domain === "www.au1.myhealthcompare.com.au" ||
      fieldsData.domain === "au1.myhealthcompare.com.au" ||
      fieldsData.domain === "cbhs.myhealthcompare.com.au" ||
      fieldsData.domain === "www.cbhs.myhealthcompare.com.au" ||
      fieldsData.domain === "www.new.myhealthcompare.com.au" ||
      fieldsData.domain === "new.myhealthcompare.com.au"
    ) {
      this.postHealthDealLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "mybroadbandcompare.com.au" ||
      fieldsData.domain === "www.mybroadbandcompare.com.au" ||
      fieldsData.domain === "www.v2.mybroadbandcompare.com.au" ||
      fieldsData.domain === "v2.mybroadbandcompare.com.au"
    ) {
      this.postMyBroadbandCompareNewLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "govthearingaids.quickandeasyhearing.com" ||
      fieldsData.domain === "www.govthearingaids.quickandeasyhearing.com" ||
      fieldsData.domain === "nz.quickandeasyhearing.com" ||
      fieldsData.domain === "www.nz.quickandeasyhearing.com" ||
      fieldsData.domain === "nz2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.nz2.quickandeasyhearing.com" ||
      fieldsData.domain === "au1.quickandeasyhearing.com" ||
      fieldsData.domain === "www.au1.quickandeasyhearing.com" ||
      fieldsData.domain === "au.quickandeasyhearing.com" ||
      fieldsData.domain === "www.au.quickandeasyhearing.com" ||
      fieldsData.domain === "nz1.quickandeasyhearing.com" ||
      fieldsData.domain === "www.nz1.quickandeasyhearing.com" ||
      fieldsData.domain === "quickandeasyhearing.com" ||
      fieldsData.domain === "www.quickandeasyhearing.com" ||
      fieldsData.domain === "aus.quickandeasyhearing.com" ||
      fieldsData.domain === "www.aus.quickandeasyhearing.com" ||
      fieldsData.domain === "newaus.quickandeasyhearing.com" ||
      fieldsData.domain === "www.newaus.quickandeasyhearing.com" ||
      fieldsData.domain === "hca.quickandeasyhearing.com" ||
      fieldsData.domain === "www.hca.quickandeasyhearing.com" ||
      fieldsData.domain === "hca2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.hca2.quickandeasyhearing.com" ||
      fieldsData.domain === "nzv2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.nzv2.quickandeasyhearing.com"
    ) {
      this.postQuickAndEasyHearingLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "canadian.quickandeasyhearing.com" ||
      fieldsData.domain === "www.canadian.quickandeasyhearing.com" ||
      fieldsData.domain === "canadian2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.canadian2.quickandeasyhearing.com" ||
      fieldsData.domain === "ca.quickandeasyhearing.com" ||
      fieldsData.domain === "www.ca.quickandeasyhearing.com" ||
      fieldsData.domain === "ca2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.ca2.quickandeasyhearing.com" ||
      fieldsData.domain === "ca1.quickandeasyhearing.com" ||
      fieldsData.domain === "www.ca1.quickandeasyhearing.com" ||
      fieldsData.domain === "ca3.quickandeasyhearing.com" ||
      fieldsData.domain === "www.ca3.quickandeasyhearing.com" ||
      fieldsData.domain === "trycanada.quickandeasyhearing.com" ||
      fieldsData.domain === "www.trycanada.quickandeasyhearing.com"
    ) {
      this.postCanadianQuickAndEasyHearingLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "myenergycompare.com.au" ||
      fieldsData.domain === "www.myenergycompare.com.au" ||
      fieldsData.domain === "gs.myenergycompare.com.au" ||
      fieldsData.domain === "www.gs.myenergycompare.com.au" ||
      fieldsData.domain === "2022.myenergycompare.com.au" ||
      fieldsData.domain === "www.2022.myenergycompare.com.au"
    ) {
      this.postMyEnergyCompareLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "justcompare.myenergycompare.com.au" ||
      fieldsData.domain === "www.justcompare.myenergycompare.com.au"
    ) {
      this.postJustcompareV2API(fieldsData);
    } else if (
      fieldsData.domain === "1stenergy.myenergycompare.com.au" ||
      fieldsData.domain === "www.1stenergy.myenergycompare.com.au"
    ) {
      this.post1stenergyMyEnergyCompareLeads(fieldsData);
    }
    else if (
      fieldsData.domain === "aussiesgivingback.com.au" ||
      fieldsData.domain === "www.aussiesgivingback.com.au"
    ) {
      this.postRSLAussiesGivingBack(fieldsData);
    }
    else if (
      fieldsData.domain === "quickeasyfinance.com.au" ||
      fieldsData.domain === "www.quickeasyfinance.com.au"
    ) {
      if (fieldsData["ages"] !== "no" && fieldsData["citizen"] !== "no") {
        this.postemaillleadquickandeasyfinace(fieldsData);
      }
    } else if (
      fieldsData.domain === "firstnational.ozhouseprice.com.au" ||
      fieldsData.domain === "www.firstnational.ozhouseprice.com.au" ||
      fieldsData.domain === "www.propertyvalue.ozhouseprice.com.au" ||
      fieldsData.domain === "propertyvalue.ozhouseprice.com.au" ||
      fieldsData.domain === "www.ozhouseprice.com.au" ||
      fieldsData.domain === "ozhouseprice.com.au" ||
      fieldsData.domain === "www.edm.ozhouseprice.com.au" ||
      fieldsData.domain === "edm.ozhouseprice.com.au"
    ) {
      this.postFirstnationalLeads(fieldsData);
    } else if (
      fieldsData.domain === "businessenergydeals.com.au" ||
      fieldsData.domain === "www.businessenergydeals.com.au"
    ) {
      this.postSMSBussinessenergyDeal(fieldsData);
    } else if (
      fieldsData.domain === "www.businessfundstoday.net" ||
      fieldsData.domain === "businessfundstoday.net"
    ) {
      this.postAPIBusinessFundsToday(fieldsData);
    }
    else if (
      fieldsData.domain === "www.kiwihouseprice.com" ||
      fieldsData.domain === "kiwihouseprice.com"
    ) {
      this.postKiwiHousePriceApi(fieldsData);
    } else if (
      fieldsData.domain === "aus.mysolarcompare.com.au" ||
      fieldsData.domain === "www.aus.mysolarcompare.com.au" ||
      fieldsData.domain === "localsolarquotes.mysolarcompare.com.au" ||
      fieldsData.domain === "www.localsolarquotes.mysolarcompare.com.au"
    ) {
      this.postAusMySolarCompareApi(fieldsData);
    } else if (
      fieldsData.domain === "mybroadbandcompare.co.nz" ||
      fieldsData.domain === "www.mybroadbandcompare.co.nz"
    ) {
      this.postMyBroadbandCompareNzleads(fieldsData);
    } else if (
      fieldsData.domain === "biz.businessfundstoday.net" ||
      fieldsData.domain === "www.biz.businessfundstoday.net" ||
      fieldsData.domain === "v2.businessfundstoday.net" ||
      fieldsData.domain === "www.v2.businessfundstoday.net" ||
      fieldsData.domain === "businessloan.financesolver.co" ||
      fieldsData.domain === "www.businessloan.financesolver.co" ||
      fieldsData.domain === "native.financesolver.co" ||
      fieldsData.domain === "www.native.financesolver.co" ||
      fieldsData.domain === "bizau.businessfundstoday.net" ||
      fieldsData.domain === "www.bizau.businessfundstoday.net"
    ) {
      this.postAPIBizBusinessFundsToday(fieldsData);
    } else if (
      fieldsData.domain === "v3.ozhouseprice.com.au" ||
      fieldsData.domain === "www.v3.ozhouseprice.com.au"
    ) {
      this.ljhHousePriceApi(fieldsData);
    } else if (
      fieldsData.domain === "v5.ozhouseprice.com.au" ||
      fieldsData.domain === "www.v5.ozhouseprice.com.au"
    ) {
      this.realestateCoApi(fieldsData);
    } else if (
      fieldsData.domain === "v6.ozhouseprice.com.au" ||
      fieldsData.domain === "www.v6.ozhouseprice.com.au" ||
      fieldsData.domain === "kwinana.ozhouseprice.com.au" ||
      fieldsData.domain === "www.kwinana.ozhouseprice.com.au"
    ) {
      this.rexApi(fieldsData);
    } else if (
      fieldsData.domain === "acquirely.shopadocket.com.au" ||
      fieldsData.domain === "www.acquirely.shopadocket.com.au" ||
      fieldsData.domain === "www.whitsundays.winrewardsonline.com" ||
      fieldsData.domain === "whitsundays.winrewardsonline.com" ||
      fieldsData.domain === "wardrobeshopping.winrewardsonline.com" ||
      fieldsData.domain === "www.wardrobeshopping.winrewardsonline.com" ||
      fieldsData.domain === "www.smarthomepackage.winrewardsonline.com" ||
      fieldsData.domain === "smarthomepackage.winrewardsonline.com" ||
      fieldsData.domain === "www.ccsmarthome.winrewardsonline.com" ||
      fieldsData.domain === "ccsmarthome.winrewardsonline.com" ||
      fieldsData.domain === "www.winaflight.winrewardsonline.com" ||
      fieldsData.domain === "winaflight.winrewardsonline.com"
    ) {
      this.shopaDocketApi(fieldsData);
    } else if (
      fieldsData.domain === "mypetinsurancecompare.co" ||
      fieldsData.domain === "www.mypetinsurancecompare.co"
    ) {
      this.petnsurApi(fieldsData);
    } else if (
      fieldsData.domain === "financesolver.co" ||
      fieldsData.domain === "www.financesolver.co" ||
      fieldsData.domain === "www.comparesuper.mysuperannuationcompare.com.au" ||
      fieldsData.domain === "comparesuper.mysuperannuationcompare.com.au" ||
      fieldsData.domain === "www.mysuperannuationcompare.com.au" ||
      fieldsData.domain === "mysuperannuationcompare.com.au" ||
      fieldsData.domain === "www.improvesuper.mysuperannuationcompare.com.au" ||
      fieldsData.domain === "improvesuper.mysuperannuationcompare.com.au" ||
      fieldsData.domain === "www.superannuation.financesolver.co" ||
      fieldsData.domain === "superannuation.financesolver.co"
    ) {
      this.superannuationcompareApi(fieldsData);
    } else if (
      fieldsData.domain === "mycarinsurancecompare.co" ||
      fieldsData.domain === "www.mycarinsurancecompare.co"
    ) {
      this.autolifeapi(fieldsData);
    } else if (
      fieldsData.domain === "energyefficiencyv2.financesolver.co" ||
      fieldsData.domain === "www.energyefficiencyv2.financesolver.co" ||
      fieldsData.domain === "energyefficiency.financesolver.co" ||
      fieldsData.domain === "www.energyefficiency.financesolver.co"
    ) {
      this.googleSheetApi(fieldsData);
    } else if (
      // fieldsData.domain === "quickandeasyhearing.com" ||
      // fieldsData.domain === "www.quickandeasyhearing.com" ||
      // fieldsData.domain === "aus.quickandeasyhearing.com" ||
      // fieldsData.domain === "www.aus.quickandeasyhearing.com" ||
      // fieldsData.domain === "newaus.quickandeasyhearing.com" ||
      // fieldsData.domain === "www.newaus.quickandeasyhearing.com" ||
      fieldsData.domain === "au2.quickandeasyhearing.com" ||
      fieldsData.domain === "www.au2.quickandeasyhearing.com" ||
      fieldsData.domain === "au3.quickandeasyhearing.com" ||
      fieldsData.domain === "www.au3.quickandeasyhearing.com" ||
      fieldsData.domain === "qehearing.com" ||
      fieldsData.domain === "www.qehearing.com" ||
      fieldsData.domain === "au2.qehearing.com" ||
      fieldsData.domain === "www.au2.qehearing.com" ||
      fieldsData.domain === "au3.qehearing.com" ||
      fieldsData.domain === "www.au3.qehearing.com"
    ) {
      this.amplifonSmsApi(fieldsData);
    } else if (
      fieldsData.domain === "nakedloans.mycarloancompare.com.au" ||
      fieldsData.domain === "www.nakedloans.mycarloancompare.com.au"
    ) {
      this.nakedloansApi(fieldsData);
    }
    else if (["www.mylifeinsurancecompare.financesolver.co", "mylifeinsurancecompare.financesolver.co"].includes(fieldsData.domain)) {
      this.myLifeInsauranceCompareFinanceSolverAPI(fieldsData)
    }
    else if (
      comparesolarmarket.includes(fieldsData.domain)
    ) {
      this.comparesolarmarketAPI(fieldsData);
    } else if (["carloan.financesolver.co", "www.carloan.financesolver.co"].includes(fieldsData.domain)) {
      this.carloanFinanceSolverAPI(fieldsData);
    }
    else {
      this.redirectToThankyouPage(fieldsData);
    }
  };

  static trackImpression = (data, querie) => {
    try {
      if (querie && (querie.utm_source || querie.gclid)) {
        var body = {
          page_id: 6,
          site_id: data.id,
          query_string:
            querie && typeof querie === "object"
              ? JSON.stringify(querie)
              : querie.toString(),
          affiliate_id: querie?.gclid || "",
          source: querie?.utm_source || "",
        };

        var baseurl = process.env.WDS_SOCKET_PATH === "release" ? "https://helathinsurancefind.com.au/api/" : "http://localhost:8034/api/";

        axios({
          method: "post",
          url: `${baseurl}impression`,
          data: qs.stringify(body),
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((result) => {
            // Do somthing
            console.log("result", result?.data?.response?.id);
            if (result?.data?.response?.id) {
              localStorage.setItem("imression_id", result?.data?.response?.id);
            }
          })
          .catch((err) => {
            // Do somthing
            localStorage.setItem("imression_id", "");
            console.log("err", err);
          });
      } else {
        localStorage.setItem("imression_id", "");
      }
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  static trackImpressionClick = (lead_id) => {
    try {
      const id = localStorage.getItem("imression_id");
      if (id && lead_id) {
        var body = {};
        body.lead_id = lead_id;
        body.id = id;

        var baseurl = "";
        if (process.env.WDS_SOCKET_PATH === "release") {
          baseurl = "https://helathinsurancefind.com.au/api/";
        } else {
          baseurl = "http://localhost:8034/api/";
        }

        axios({
          method: "patch",
          url: `${baseurl}impression`,
          data: qs.stringify(body),
          headers: {
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
        })
          .then((result) => {
            // Do somthing
            console.log("result", result);
          })
          .catch((err) => {
            // Do somthing
            console.log("err", err);
          });
      }
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  static trackError = (exception, data) => {
    try {
      var body = {};
      body.exception =
        typeof exception === "object" && exception !== null
          ? JSON.stringify(exception)
          : exception.toString();
      body.lead_id = data?.lead_id;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}trackingerror`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        });
    } catch (ex) {
      this.redirectToThankyouPage(data);
    }
  };

  static trackAdvertlyPluginError = (exception) => {
    try {
      var body = {};
      body.exception =
        typeof exception === "object" && exception !== null
          ? JSON.stringify(exception)
          : exception.toString();
      body.lead_id = 0;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}trackingerror`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          console.log("success in advertly track error");
        })
        .catch((err) => {
          // Do somthing
          console.log("exception in advertly track error catch", err);
        });
    } catch (ex) {
      console.log("exception in advertly track error catch - v1", ex);
    }
  };

  //Hubspot api section for Support with Debt
  static postHubSpotDataoftutorexpert = (data) => {
    try {
      var body = {};
      body.enrol_by = data.role;
      body.email = data.email;
      body.firstname = data.fName;
      body.lastname = data.lName;
      body.mobilephone = data.phone_number;
      body.find_program_curriculum = data.state;
      body.find_program_year_level = data.year;
      body.find_program_discipline = data.subject;
      body.lifecyclestage = "Lead";
      body.last_source = "acquirely";
      body.pageurl = data.domain + "/getstarted";
      body.pagename = "About Us";
      body.last_campaign = "native-parents-creative-3";
      body.last_offer = "20-percent-off";
      body.last_page = data.domain + "/getstarted";
      body.lead_id = data.lead_id;

      const cookies = new Cookies();
      const htuk = cookies.get("hubspotutk");

      body.cookie_hubspotutk =
        htuk && htuk !== "" && htuk !== undefined ? htuk : "";
      body.remoteaddress = data.IPv4;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      axios({
        method: "post",
        url: `${baseurl}hubspottutorexpert`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //Debtrelief support with debt API
  static postDebtReliefleads = (data) => {
    try {
      var body = {};
      body.email = data.email;
      body.first_name = data.fName;
      body.last_name = data.lName;
      body.phone = data.phone_number;
      body.source = "acquirely";
      body.debt_amount = data.unsecured_debt;
      body.lead_id = data.lead_id;
      body.domain = data.domain;
      body.fast_tracked = data.fast_tracked || "N/A";

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}debtrelief`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //my health compare lead API
  static postHealthDealLeads = (data) => {
    try {
      var body = {};
      body.firstname = data.fName || data.full_name;
      body.lastname = data.lName || data.full_name;
      body.email = data.email;
      body.phone = data.phone_number;
      body.covered = data.covered;
      body.provider = data.provider;
      body.state = data.state;
      body.lead_id = data.lead_id;
      body.domain = data.domain;
      if (data.postcode) {
        body.postcode = data.postcode;
      }
      if (data.cover) {
        body.cover = data.cover;
      }
      if (
        data.domain === "www.cbhs.myhealthcompare.com.au" ||
        data.domain === "cbhs.myhealthcompare.com.au"
      ) {
        body.question1 = data.eligibilty;
        body.ausResident = data.ausResident;
        body.age = data.age;
      }

      if (
        data.domain === "www.adwords1.myhealthcompare.com.au" ||
        data.domain === "adwords1.myhealthcompare.com.au" ||
        data.domain === "www.au.myhealthcompare.com.au" ||
        data.domain === "au.myhealthcompare.com.au" ||
        data.domain === "www.healthdeal.myhealthcompare.com.au" ||
        data.domain === "healthdeal.myhealthcompare.com.au" ||
        data.domain === "www.compare.myhealthcompare.com.au" ||
        data.domain === "compare.myhealthcompare.com.au"
      ) {
        body.source = "Adwords1myhealthcompare";
      } else {
        body.source = "myhealthcompare";
      }

      let url;
      if (process.env.WDS_SOCKET_PATH === "release") {
        url = "https://helathinsurancefind.com.au/api/";
      } else {
        url = "http://localhost:8034/api/";
      }
      axios
        .post(`${url}healthdeal`, body, {
          headers: {
            Authorization: "apiKeyhdlp5P_Fsp1d",
            "content-type": "application/json",
          },
        })
        .then((result) => {
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //New Api section for MyBroadbandCompare - Added on 22 March 2021 - CRS-268
  static postMyBroadbandCompareNewLeads = (data) => {
    try {
      var body = {};

      body.source = "MYBROADBANDCOMPARE";
      body.email = data.email;
      body.phone = data.phone_number;
      body.name = data.full_name;
      body.state = data.state;
      body.provider = data.current_internet_provider;
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}dodobroadbandcompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //api section for QuickAndEasyHearing
  static postQuickAndEasyHearingLeads = (data) => {
    try {
      var body = {};
      var postc = data.postcode.split(",");

      if (postc.length >= 3) {
        body.postcode = postc[2].trim(" ");
      } else {
        body.postcode = data.postcode;
      }
      body.transactionid = "N/A";
      body.firstName = data.fName;
      body.lastName = data.lName;
      body.telephone = data.phone_number;
      body.email = data.email;
      // body.postcode = data.postcode;
      body.address = data.postcode;
      body.state = data.state;
      body.city = "N/A";
      body.haveHearingAids = "N/A";
      body.Age = data.age;
      body.privateHealthInsurance = "N/A";
      body.tinnitus = "N/A";
      body.dvaCard = "N/A";
      body.pensioner = "N/A";
      body.Country = "Australia";
      body.respondingcampaign = "OBEASYHEAR";
      body.leadchannel = "Campaign / Marketing";
      body.dataowner = "Easy Hear";
      body.externlid = "N/A";
      body.domain = data.domain;
      body.lead_id = data.lead_id;
      if (
        data.domain === "www.nz.quickandeasyhearing.com" ||
        data.domain === "nz.quickandeasyhearing.com" ||
        data.domain === "www.nz2.quickandeasyhearing.com" ||
        data.domain === "nz2.quickandeasyhearing.com" ||
        data.domain === "www.nz1.quickandeasyhearing.com" ||
        data.domain === "nz1.quickandeasyhearing.com" ||
        data.domain === "www.nzv2.quickandeasyhearing.com" ||
        data.domain === "nzv2.quickandeasyhearing.com"
      ) {
        body.Country = "New Zealand";
        body.respondingcampaign = "BH - Easy Hear";
        body.state = postc[0].trim(" ");
      }
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}quickandeasyhearingAPI`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //Added on 22 sept 2021 LP-518 (LP-505) - for canadian versions
  static postCanadianQuickAndEasyHearingLeads = (data) => {
    try {
      var body = {};
      // var postc = data.postcode.split(",");

      // if (postc.length >= 3) {
      //   body.postcode = postc[2].trim(" ");
      // } else {
      //   body.postcode = data.postcode;
      // }
      body.postcode = data.textzipcode;
      body.firstName = data.fName;
      body.lastName = data.lName;
      body.telephone = data.phone_number;
      body.email = data.email;
      body.gender = data.gender;
      // body.state =data.textstate ;//postc[1].trim(" ");
      // body.city = data.textcity;//postc[0].trim(" ");
      body.haveHearingAids = data.haveHearingAids;
      body.Age = data.age;
      body.struggleToHear = data.struggleToHear;
      body.lead_id = data.lead_id;
      // console.log("body", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}canadianquickandeasyhearingAPI`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //New GoSwitch Api section for gs.myenergycompare.com.au - Added on 30 March 2021
  static postGoSwitchApiGSMyEnergyCompareLeads = (data) => {
    try {
      var fn = data.full_name.split(" ");
      var aarAddress = data.postcode.split(", ");
      var usage = data.usage?.split(" -");
      var fueltype =
        data.service === "Electricity & Gas" ? "Dual" : data.service;

      var datastring = qs.stringify({
        domain: data.domain,
        Source: "GS",
        Email: data.email,
        FirstName: fn && fn.length > 0 ? fn[0] : "",
        LastName: fn && fn.length > 1 ? fn[1] : "",
        PhoneNumber: data.phone_number,
        PhoneNumber2: data.phone_number,
        PostCode: aarAddress && aarAddress.length > 2 ? aarAddress[2] : "",
        ReferrId: "1",
        BusinessName: "",
        ABN: "",
        Comments: "",
        GSFId: "",
        //Utm_Source: "AcqEdm",
        Utm_Source: "acqmyenergycompare",
        ContactTime: "",
        Suburb: aarAddress && aarAddress.length > 0 ? aarAddress[0] : "",
        MoveIn: "false",
        FuelType: fueltype,
        CustomerType: "home",
        SolarPanel: "false",
        ElectricityUsage: usage && usage[0],
        GasUsage: usage && usage[0],
        BillUpload: "",
        SolarInterested: "false",

        lead_id: data.lead_id,
      });

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}goswitchmyenergycompare`,
        data: datastring,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // Justcompare.myenergycompare and v2.mybroadbandcompare apis, added on 15 nov 2021 (LP-531)
  static postJustcompareV2API = (data) => {
    try {
      var fn = data.full_name.split(" ");
      var aarAddress = data.postcode.split(", ");
      var datastring = qs.stringify({
        campaign: data.domain,
        source: "Acquirely",
        medium: "Leadgen",
        contactCode: "ACQ",
        email: data.email,
        firstName: fn && fn.length > 0 ? fn[0] : "",
        lastName: fn && fn.length > 1 ? fn[1] : "",
        mobile: data.phone_number.replace("+61", "0").replace("+64", "0"),
        postcode: aarAddress && aarAddress.length > 2 ? aarAddress[2] : "",
        state:
          data.state === undefined
            ? aarAddress && aarAddress.length > 2
              ? aarAddress[1]
              : ""
            : data.state,
        broadbandDeal:
          data.domain === "www.v2.mybroadbandcompare.com.au" ||
          data.domain === "v2.mybroadbandcompare.com.au",
        energyGasDeal:
          data.domain === "www.justcompare.myenergycompare.com.au" ||
          data.domain === "justcompare.myenergycompare.com.au" ||
          data.domain === "2022.myenergycompare.com.au" ||
          data.domain === "www.2022.myenergycompare.com.au",
        // email: data.email,
        lead_id: data.lead_id,
      });

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}justcomparev2api`,
        data: datastring,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //New Api section for 1stenergy.myenergycompare.com.au - Added on 23 March 2021
  static post1stenergyMyEnergyCompareLeads = (data) => {
    try {
      var body = {};
      var fn = data.full_name.split(" ");
      var aarAddress = data.postcode.split(", ");
      body.sname = fn && fn.length > 1 ? fn[1] : "";
      body.fname = fn && fn.length > 0 ? fn[0] : "";
      body.email = data.email;
      body.phone = data.phone_number;
      body.state = data.state;
      body.pc = aarAddress && aarAddress.length > 2 ? aarAddress[2] : "";
      body.address = data.postcode;
      body.service = data.service;
      body.usage = data.usage;
      body.source = "acquirely";
      body.type = "edm";
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}teleconnex1stenergy`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //RSL Coreg Aussie Givivng Back method
  static postRSLAussiesGivingBack = (data) => {
    try {
      var body = {};

      var fullname = data.full_name && data.full_name.split(" ");
      body.firstname = fullname.length > 0 && fullname[0].replace(/'/g, "");
      body.lastname = fullname.length > 1 && fullname[1].replace(/'/g, "");
      body.url = `https://aussiesgivingback.com.au/rsl-aussies-giving-back`;
      body.phone = data.phone_number;
      body.email = data.email;
      var aarAddress = data.postcode && data.postcode.split(", ");
      body.city = aarAddress && aarAddress.length > 2 ? aarAddress[0] : "";
      body.state = aarAddress && aarAddress.length > 2 ? aarAddress[1] : "";
      body.zip = aarAddress && aarAddress.length > 2 ? aarAddress[2] : "";
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}rslcoregaussiesgiving`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // Quick easy finace API to send email of leads
  static postemaillleadquickandeasyfinace = (data) => {
    try {
      var body = {};

      var fullname = data.full_name && data.full_name.split(" ");
      body.firstname = fullname.length > 0 && fullname[0];
      body.lastname = fullname.length > 1 && fullname[1];
      body.url = window.location.href;
      body.phone = data.phone_number;
      body.email = data.email;
      body.address = data.address;
      body.age = data.ages;
      body.citizen = data.citizen;
      body.loanamount = data.loan_amount;
      body.loanterm = data.loan_term;
      body.owncar = data.own_car;
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}quickeasyfinanceemaillead`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // Bussiness energy API to send email of leads
  static postSMSBussinessenergyDeal = (data) => {
    try {
      var body = {};
      body.lead_id = data.lead_id;
      body.phone = data.phone_number;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}smsbussinessenergy`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // Capify api - Business funds today API added on 26 july 2021
  static postAPIBusinessFundsToday = (data) => {
    try {
      var body = {};

      var fullname = data.full_name && data.full_name.split(" ");
      body.first_name = fullname.length > 0 && fullname[0]; //first_name
      body.last_name = fullname.length > 1 ? fullname[1] : fullname[0]; //last_name
      body.company_name = data.business_name; //company
      body.phone = data.phone_number; //mobile_phone
      body.email = data.email; //email
      body.loan_purpose = data.loan_purpose; //loan_purpose
      body.est_loan_amount = data.est_loan_amount; //requested_amount
      body.state = data.state; //state
      body.months_trading = data.est_time_trading; //months_trading
      body.lead_id = data.lead_id;
      body.monthly_revenue = data.monthly_revenue;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}businessfundstodaycapify`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //LP-606 Biz.businessfundapi  added on 2022-05-19
  static postAPIBizBusinessFundsToday = (data) => {
    try {
      var body = {};

      var fullname = data.full_name && data.full_name.split(" ");
      body.first_name = fullname.length > 0 && fullname[0]; //first_name
      body.last_name = fullname.length > 1 ? fullname[1] : fullname[0]; //last_name
      body.company_name = data.business_name; //company
      body.phone = data.phone_number; //mobile_phone
      body.email = data.email; //email
      body.loan_purpose = data.loan_purpose; //loan_purpose
      body.est_loan_amount = data.est_loan_amount; //requested_amount
      body.state = data.state; //state
      body.months_trading = data.est_time_trading; //months_trading
      body.lead_id = data.lead_id;
      body.monthly_revenue = data.monthly_revenue;
      body.lead_description = data.lead_description;
      body.lead_source_primary = "Acquirely";
      body.lead_source_secondary = "Google";
      // if (
      //   window.location.host === "businessloan.financesolver.co" ||
      //   window.location.host === "www.businessloan.financesolver.co"
      // ) {
      //   body.lead_source_secondary = "facebook";
      // } //commented out as per request on 17 oct 2022

      if (
        window.location.host === "native.financesolver.co" ||
        window.location.host === "www.native.financesolver.co"
      ) {
        body.lead_source_secondary = "native";
      }

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}valiantapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // kiwihouseprice apis, added on 19 nov 2021 (CRS-459)
  static postKiwiHousePriceApi = (data) => {
    try {
      var fn = data.full_name.split(" ");

      const pacode =
        localStorage.getItem("pacode") != undefined
          ? JSON.parse(localStorage.getItem("pacode"))
          : null;

      //var aarAddress = data.postcode && data.postcode.split(", ");
      var datastring = qs.stringify({
        first_name: fn && fn.length > 0 ? fn[0] : "",
        last_name: fn && fn.length > 1 ? fn[1] : "",
        mobile: data.phone_number
          .replace("+61", "0")
          .replace("+64", "0")
          .replace(" ", "")
          .replace(" ", ""),
        email: data.email,
        street: "",
        city: pacode && pacode.city,
        state: pacode && pacode.suburb,
        zip: pacode && pacode.postcode,
        country: "NZ",
        "00N2x000008M0Dg": data.homeowner,
        "00N2x000008M0Dm": data.sellproperty || "",
        lead_id: data.lead_id,
      });
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}kiwihousepriceapi`,
        data: datastring,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  // aus.mysolarcompare.com.au apis, added on 01 feb 2022 in email detail
  static postAusMySolarCompareApi = (data) => {
    // console.log("data",data)
    try {
      var body = {};

      var fullname = data.full_name && data.full_name.split(" ");
      body.firstName = fullname.length > 0 && fullname[0];
      body.lastName = fullname.length > 1 ? fullname[1] : "";
      body.phone = data.phone_number;
      body.email = data.email;
      if (data.postcode) { body.postcode = data.postcode; }
      //var postcodeArray = data.postcode.split(", ");
      body.raw = data.txt_address;
      // body.postCode =
      //   postcodeArray && postcodeArray.length > 1 && postcodeArray[2];

      body.leadTypes = data.interested_in_solar;
      body.isOwner = data.home_owner === "Homeowner";
      body.roofType = data.roof_type;
      body.storeys = data.property;
      body.lead_id = data.lead_id;
      body.avg_bill = data.avg_bill;
      body.time_frame = data.time_frame;
      body.quotes = data.quotes;
      if (data.state) {
        body.state = data.state;
      }
      body.domain = data.domain;
      //console.log("data1", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      // {console.log("body",qs.stringify(body))}
      axios({
        method: "post",
        url: `${baseurl}aussolarcompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };
  // 	mybroadbandcompare.co.nz api, added on 21 Mar 2022 l
  static postMyBroadbandCompareNzleads = (data) => {
    try {
      var body = {};
      var fullname = data.full_name && data.full_name.split(" ");
      body.firstName = fullname.length > 0 && fullname[0];
      body.lastName = fullname.length > 1 ? fullname[1] : "";
      body.phone = data.phone_number;
      body.email = data.email;

      const pacode =
        localStorage.getItem("pacode") != undefined
          ? JSON.parse(localStorage.getItem("pacode"))
          : null;
      //var postcodeArray = data.postcode.split(", ");
      body.postCode = pacode && pacode.postcode;
      body.lead_id = data.lead_id;
      body.city = pacode && pacode.city;
      body.state = pacode && pacode.suburb;
      body.country = "NZ";
      body.broadband_provider = data.broadband_provider;
      body.monthly_cost = data.monthly_cost;
      body.service_type = data.service_type;
      // console.log("data1", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      // {console.log("body",qs.stringify(body))}
      axios({
        method: "post",
        url: `${baseurl}nzbroadbandcompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          console.log("result", result);
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };
  //LP-613 v3.ozhouseprice.com.au api added on 2022-06-15
  static ljhHousePriceApi = (data) => {
    try {
      var body = {};
      var postcodeArray = data.postcode.split(", ");
      var suburb = data.postcode;
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2];
      body.Name = (data.fName || "") + " " + (data.lName || "");
      body.Email = data.email;
      body.Phone = data.phone_number.replace(/\+61/g, "0");
      body.Suburb = suburb;
      body.Postcode = postcode;
      body.lead_id = data.lead_id;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      var config = {
        method: "post",
        url: `${baseurl}ljhhousepriceapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      //  console.log("config",config);
      axios(config)
        .then((result) => {
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static realestateCoApi = (data) => {
    try {
      var body = {};
      body.first_name = data.fName || "";
      body.last_name = data.lName || "";
      body.email = data.email;
      body.phone = data.phone_number.replace(/\+61/g, "0");
      body.property_address = data.postcode;
      body.lead_id = data.lead_id;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      var config = {
        method: "post",
        url: `${baseurl}realestatecoapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      //  console.log("config",config);
      axios(config)
        .then((result) => {
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static rexApi = (data) => {
    try {
      var body = {};
      body.first_name = data.fName || "";
      body.last_name = data.lName || "";
      body.email = data.email;
      body.phone = data.phone_number.replace(/\+61/g, "0");
      body.property_address = data.postcode;
      if (
        data.domain === "kwinana.ozhouseprice.com.au" ||
        data.domain === "www.kwinana.ozhouseprice.com.au"
      ) {
        body.sendto = "isabel@coulsonandco.com.au";
        body.subject = "New Coulson and Co. lead generated";
      } else {
        body.sendto = "ben@hennessyrealestate.com.au";
        body.subject = "Hennessy Real Estate leads";
      }
      body.lead_id = data.lead_id;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      var config = {
        method: "post",
        url: `${baseurl}rexapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      //  console.log("config",config);
      axios(config)
        .then((result) => {
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static shopaDocketApi = (data) => {
    try {
      var body = {};
      body.first_name = data.fName || "";
      body.last_name = data.lName || "";
      body.email = data.email;
      body.phone = data.phone_number.replace(/\+61/g, "0");
      body.age = data.age;
      body.address = data.address;
      body.postcode = data.postcode;
      body.lead_id = data.lead_id;
      body.country = "AU";
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      var config = {
        method: "post",
        url: `${baseurl}shopadocket-to-databowl`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      console.log("body", body);
      axios(config)
        .then((result) => {
          console.log("successfully submitted");
        })
        .catch((err) => {
          console.log("Error while sendng: ", err);
        });
    } catch (ex) {
      console.log("Error while sendng: ", ex);
    }
  };

  static petnsurApi = (data) => {
    try {
      var body = {};
      var fullname = data.full_name && data.full_name.split(" ");
      body.firstName = fullname.length > 0 && fullname[0];
      body.lastName = fullname.length > 1 ? fullname[1] : "";
      body.phone = data.phone_number;
      body.email = data.email;
      body.petnsurtype = data.petnsurtype;
      body.petnsurage = data.petnsurage;
      body.landline = data.landline;
      body.lead_id = data.lead_id;
      body.petsname = data.petname;
      // console.log("data1", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      // {console.log("body",qs.stringify(body))}
      axios({
        method: "post",
        url: `${baseurl}mypetinsurancecompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // console.log("result", result);
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static superannuationcompareApi = (data) => {
    try {
      var body = {};
      var fullname = data.full_name && data.full_name.split(" ");
      body.firstName = fullname.length > 0 && fullname[0];
      body.lastName = fullname.length > 1 ? fullname[1] : "";
      body.phone = data.phone_number;
      body.email = data.email;
      body.lead_id = data.lead_id;
      // console.log("data1", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      // {console.log("body",qs.stringify(body))}
      axios({
        method: "post",
        url: `${baseurl}superannuationcompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // console.log("result", result);
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static autolifeapi = (data) => {
    try {
      var body = {};
      var fullname = data.full_name && data.full_name.split(" ");
      body.firstName = fullname.length > 0 && fullname[0];
      body.lastName = fullname.length > 1 ? fullname[1] : "";
      var postcodearr = data.postcode && data.postcode.split(", ");
      if (postcodearr && postcodearr.length > 1) {
        body.postcode = postcodearr[2];
        body.state = postcodearr[1];
        body.city = postcodearr[0];
      }
      body.phone = data.phone_number;
      body.email = data.email;
      body.address = data.postcode;
      body.regplate = data.regplate;
      body.carmake = data.carmake;
      body.lead_id = data.lead_id;
      body.carmodel = data.carmodel;
      body.manufactureyear = data.manufactureyear;
      body.kmtravel = data.kmtravel;
      // console.log("data1", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      //console.log("body", body);
      axios({
        method: "post",
        url: `${baseurl}mycarinsurancecompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // console.log("result", result);
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static googleSheetApi = (data) => {
    try {
      console.log("google sheet api", data);
      var fullname = data.full_name && data.full_name.split(" ");
      var body = {
        firstname: fullname.length > 0 && fullname[0],
        lastname: fullname.length > 1 ? fullname[1] : "",
        phone: data.phone_number,
        email: data.email,
        address: data.address,
        product: data.product,
        lead_id: data.lead_id,
        domain: window.location.host,
      };
      console.log("body--", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}updategooglesheetapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static getAddressMetadataWithPxid = async (pxid) => {
    if (pxid) {
      var baseurl = Utils.getHost().apiUrl;

      var config = {
        method: "get",
        url: `${baseurl}addressfindernz/${pxid}`,
        headers: {
          Authorization: "NXT9QKHWCD4LEYVB3FRP-KOINASAKE",
          "Content-Type": "application/json",
        },
      };
      try {
        const result = await axios(config);
        return result;
      } catch (err) {
        console.log("err", err);
        return [];
      }
    }
  };

  static amplifonSmsApi = (data) => {
    try {
      var body = {};
      body.phone = data.phone_number;
      body.lead_id = data.lead_id;
      var postcodearr = data.postcode && data.postcode.split(", ");
      if (postcodearr && postcodearr.length > 1) {
        body.state = postcodearr[1];
      }
      // console.log("body--", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}amplifonquickandeasysmsapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static nakedloansApi = (data) => {
    try {
      var body = {};
      var postcodeArray =
        data.postcode &&
        data.postcode.includes(",") &&
        data.postcode.split(", ");
      body.postcode =
        postcodeArray && postcodeArray.length > 2 && postcodeArray[2] || "";
      body.city = postcodeArray && postcodeArray.length > 0 && postcodeArray[0] || "";
      body.state =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[1] || "";
      body.creditrating = data.creditrating;
      body.domain = data.domain;
      body.email = data.email;
      body.employmentstatus = data.employmentstatus;
      body.fname = data.fName;
      body.grossincome = data.grossincome;
      body.ispropertyowner = data.ispropertyowner;
      body.lname = data.lName;
      body.lead_id = data.lead_id;
      body.phone = data.phone_number.startsWith("+61") ? data.phone_number.replace("+61", "0") : data.phone_number;
      body.address = data.postcode;
      body.purchaseprice = data.purchaseprice;
      // console.log("data--", qs.stringify(body));
      // console.log("body--", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}nakedloansambitioncloudapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
          // console.log("result",result);
        })
        .catch((err) => {
          // console.log("err",err);
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static comparesolarmarketAPI = (data) => {
    try {
      var body = {};
      body.propertyType = data.propertytype;
      body.capitalvalue = data.capitalvalue;
      body.combinedIncome = data.combinedincome;
      body.billPerQuarter = data.billperquarter;
      body.shadingIssue = data.shadingissue;
      body.address = data.address;
      body.fname = data.fName;
      body.lname = data.lName;
      body.email = data.email;
      body.phone = data.phone_number.startsWith("+61") ? data.phone_number.replace("+61", "0") : data.phone_number;
      body.domain = data.domain;
      body.lead_id = data.lead_id;
      body.siteId = data.site_id;
      if (data.createDate) {
        body.createDate = new Date(data.createDate).toLocaleString("en-AU", { hour12: false, timeZone: "Australia/Sydney" })
      }
      // console.log("data--", qs.stringify(body));
      // console.log("body--", body);
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}comparesolarmarketapi`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
          // console.log("result",result);
        })
        .catch((err) => {
          // console.log("err",err);
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };
  static carloanFinanceSolverAPI = (data) => {
    try {
      
      const postCode = data.postcode.split(', ');
      console.log(data);
      let phone = data.phone_number.startsWith("+61") || data.phone_number.startsWith("0") ?
       data.phone_number.replace("+61", "").replace("0","") : 
       data.phone_number;
      phone = "0"+phone.replace(/.{3}/g, '$&+');
      phone = phone.endsWith("+") ? phone.substring(0,phone.length-1) : phone;
      
      var body = {
        loanAmount: data.lookingborrow,
        state: postCode[1],
        makeAndModel: "",
        employmentStatus: data.employment_status,
        firstName: data.fName,
        lastName: data.lName,
        email: data.email,
        phone,
        domain: data.domain,
        lead_id: data.lead_id,
        siteId: data.site_id,
      }
      
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}carloan_financesolver_co`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
          // console.log("result",result);
        })
        .catch((err) => {
          // console.log("err",err);
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };
  static redirectToThankyouPage = (payload) => {
    var userdata = {};
    if (payload?.full_name) {
      const nameArr = payload.full_name.split(" ");
      if (nameArr && nameArr.length > 0) {
        userdata.fname = nameArr[0];
      }
      if (nameArr && nameArr.length > 1) {
        userdata.lname = nameArr[1];
      }
    } else {
      userdata.fname = payload?.fName;
      userdata.lname = payload?.lName;
    }

    var postcodeArray =
      payload.postcode &&
      payload.postcode.includes(",") &&
      payload?.postcode.split(", ");
    userdata.postcode =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[2]) || "";
    userdata.city =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[0]) ||
      payload?.town_city ||
      "";
    userdata.state =
      (postcodeArray && postcodeArray.length > 1 && postcodeArray[1]) ||
      payload?.state ||
      "";

    userdata.gender = payload?.gender || payload?.sex || "";
    userdata.address = payload?.address || "";
    userdata.emailId = payload?.email || "";
    userdata.phone = payload?.phone_number || payload?.phone || "";
    userdata.age = payload?.age || 0;
    userdata.dob = payload?.dob || null;
    localStorage.setItem("userdata", JSON.stringify(userdata));

    const queryParam = history.location.search || "";
    let url = '/' + window.location.pathname.split('/')[1] + (`${payload?.thankyou_page_url}` || "/thank-you") + queryParam;
    setTimeout(() => {
      window.location.href = url;
    }, 1000);
  };

  // **********************Disabled API's here below*********************************

  //Hubspot api section for Support with Debt
  static postHubSpotDatabowlleads = (data) => {
    try {
      var body = {};

      body.email = data.email;
      body.firstname = data.fName;
      body.lastname = data.lName;
      body.phone = data.phone_number;
      body.zip = data.postcode;
      body.unsecured_debt = data.unsecured_debt;
      body.home_ownership = data.home_ownership;
      body.income = data.income;
      body.campaigncd = "PR_ACQUIRELY";
      body.pageurl = data.domain + "/survey_form";
      body.pagename = "About Us";
      body.lead_id = data.lead_id;

      const cookies = new Cookies();
      const htuk = cookies.get("hubspotutk");

      body.cookie_hubspotutk =
        htuk && htuk !== "" && htuk !== undefined ? htuk : "";
      body.remoteaddress = data.IPv4;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}hubspot`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //api method is for DS.Support with Debt
  static postDSSupportWithdept = (data) => {
    try {
      var body = {};
      var postcodeArray = data.postcode.split(", ");
      var state = postcodeArray && postcodeArray.length > 1 && postcodeArray[1];
      body.firstname = data.fName;
      body.lastname = data.lName;
      body.zip = data.postcode;
      body.state = state;
      body.phone = data.phone_number;
      body.email = data.email;
      body.unsecured_debt = data.unsecured_debt;
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }
      axios({
        method: "post",
        url: `${baseurl}dssupportwithdept`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //DODO api section for MyBroadbandCompare - disabled on 22 March 2021 - CRS-268
  static postMyBroadbandCompareDodoLeads = (data) => {
    try {
      var body = {};

      body.affiliate_id = 1823; //1837;
      body.affiliate_name = "Acquirely"; //"MyBroadbandCompare";
      body.offer_id = 1232; //1791;
      body.offer_name = "iPrimus NBN Plans Apr20"; //"iPrimus NBN Plans Apr20"; //"NBN Plans July20 [DODO_A02312]";
      body.source = "Aff_Acq_iPrimus_BB_Plans_Compare"; //"Aff_Acq_iPrimus_BB_Plans_Compare"; //"Aff_Acq_Dodo_BB_Plans_Compare";
      body.affiliate_source = "Comparison_site";
      body.aff_sub5 = "P";
      body.aff_sub = "mybroadbandcompare.com.au";

      body.Custom19 = data.primary_reason;
      body.aff_lead_id = "";
      body.create_time = new Date().toString("yyyy-mm-dd hh:mm:ss");
      body.Aff_sub2 = "[PUBLEADID]";

      body.email = data.email;
      body.first_name = data.full_name;
      body.last_name = data.full_name;
      body.phone = data.phone_number;
      body.name = data.full_name;
      body.state = data.state;
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}dodobroadbandcompare`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  //api section for MyEnergyCompare
  //  with ref of jira ticket CRS-343 ,using same api jus like in gs.myenergycompare on 3 may2021:12:39 (ind)
  // enabled on 2022-06-21 LP-614
  static postMyEnergyCompareLeads = (data) => {
    try {
      var body = {};
      var fullname = data.full_name && data.full_name.split(" ");
      var postcodeArray = data.postcode.split(", ");
      body.source = "MYENERGYCOMPARE"; //"ACQUIRELYDOI - MUSTHAVEIT";
      body.firstname = fullname.length > 0 && fullname[0]; //data.full_name;
      body.lastname = fullname.length > 1 ? fullname[1] : ""; //data.full_name;
      body.phone = data.phone_number.replace(/\+61/g, "0");
      body.email = data.email;
      body.state =
        data.state || (postcodeArray && postcodeArray.length > 2)
          ? postcodeArray[1]
          : "";
      body.comments = "";
      body.lead_id = data.lead_id;

      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}addMyEnergyCompareAPI`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static postFirstnationalLeads = (data) => {
    try {
      var body = {};
      var postcodeArray = data.postcode.split(", ");
      var suburb =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[0];
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2];

      body.firstname = data.fName;
      body.lastname = data.lName;
      body.email = data.email;
      body.phone = data.phone_number.replace(/\+61/g, "0");
      body.address = data.address;
      body.suburb = suburb;
      body.state = data.state;
      body.postcode = postcode;
      body.source = data.source
        ? data.source
        : data.domain === "www.edm.ozhouseprice.com.au" ||
          data.domain === "edm.ozhouseprice.com.au"
          ? "edm"
          : "native";
      body.lead_id = data.lead_id;
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}firstNationalAPI`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          this.redirectToThankyouPage(data);
        })
        .catch((err) => {
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };

  static trackMicrositeSteps = async (session_id, step, text, value, query) => {
    if (
      session_id != null &&
      session_id != undefined &&
      session_id != "" &&
      step >= 0
    ) {
      try {
        var requestData = {
          session_id,
          domain: window.location.host,
          step,
          text,
          value,
          query,
        };
        var baseurl = Utils.getHost().apiUrl;

        var axios = require("axios");
        var data = JSON.stringify(requestData);

        var config = {
          method: "post",
          url: `${baseurl}tracksteps`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then(function (response) { })
          .catch(function (error) {
            console.log(error);
          });
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  static myLifeInsauranceCompareFinanceSolverAPI = (data) => {
    try {
      console.log("data", data);
      var body = {
        firstname: data.fName,
        lastname: data.lName,
        email: data.email,
        phone: data.phone_number.startsWith("+61") ? data.phone_number.replace("+61", "0") : data.phone_number,
        domain: data.domain,
        lead_id: data.lead_id,
        siteId: data.site_id,
        postcode: data.postcode,
        state: data.state,
        gender: data.gender,
        age: data.age,
        amount: data.amount
      }
      if (data.createDate) {
        body.createDate = new Date(data.createDate).toLocaleString("en-AU", { hour12: false, timeZone: "Australia/Sydney" })
      }
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api/";
      } else {
        baseurl = "http://localhost:8034/api/";
      }

      axios({
        method: "post",
        url: `${baseurl}mylifeinsaurancecompare_financesolver`,
        data: qs.stringify(body),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((result) => {
          // Do somthing
          this.redirectToThankyouPage(data);
          // console.log("result",result);
        })
        .catch((err) => {
          // console.log("err",err);
          // Do somthing
          this.trackError(err, data);
        });
    } catch (ex) {
      this.trackError(ex, data);
    }
  };
}

export default Api;
