import React from "react";
import { object, number } from "prop-types";
import { Helmet } from "react-helmet";
import Utils from "../../../utils/index";
import PhoneIcon from "../../../assets/images/phone.svg";

const HeaderPage = ({ siteData, page, pageType }) => {
  const handleClick = ({ page_url, is_external, target }) => {
    // if (page && page !== undefined && (page === 7 || page === "7")) {
    //   window.location.href = `/${window.location.pathname.split('/')[1]}${page_url}`;
    // } else {
      Utils.handleClickUrl(page_url, is_external, target);
    // }
  };

  let siteDataValue = siteData.content_manage_sites.filter(filterByID)[0];
  if (
    (siteDataValue === undefined && pageType !== "donotqualify") ||
    siteDataValue === null
  ) {
    window.location.href = '/' + window.location.pathname.split('/')[1] +"/page-not-found5";
  }

  function filterByID(item) {
    if (item.page_id === (page === 6 ? 1 : page)) {
      return item;
    }
  }

  const landing = Utils.getPageContent(siteData, 1);
  const contact_us = Utils.getPageContent(siteData, 3);
  const about_us = Utils.getPageContent(siteData, 2);
  const blog_page = Utils.getPageContent(siteData, 8);
  const survey_page = Utils.getPageContent(siteData, 6);

  const renderLogo = (siteData) => {
    return siteData && siteData.logo ? (
      <img
        src={siteData.logo}
        alt={siteData.logo_alt_text}
        title={siteData.logo_alt_text}
        className="img-fluid"
      />
    ) : (
      <></>
    );
  };
  var hideLogoLink = Utils.hideLogoLink(landing);
  return (
    <div className="header-main-div">
      {siteDataValue ? (
        <Helmet>
          <title>{siteDataValue.meta_title}</title>
          <meta name="description" content={siteDataValue.meta_description} />
          <link rel="icon" href={siteData.favicon} sizes="16x16" />
        </Helmet>
      ) : (
        <></>
      )}
      <header className="header-section">
        <nav
          className="navbar navbar-expand-lg navbar-light fixed-top"
          style={{ backgroundColor: siteData.primary_color }}
        >
          <div className="container">
            {hideLogoLink ? (
              renderLogo(siteData)
            ) : (
              <span
                className="navbar-brand"
                onClick={() => handleClick({ page_url: "/" })}
              >
                {renderLogo(siteData)}
              </span>
            )}
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {about_us && (
                  <li className={`nav-item ${page === 2 && "active"}`}>
                    <span
                      className="nav-link"
                      onClick={() => handleClick(about_us)}
                    >
                      About Us
                    </span>
                  </li>
                )}
                {contact_us && (
                  <li className={`nav-item ${page === 3 && "active"}`}>
                    <span
                      className="nav-link"
                      onClick={() => handleClick(contact_us)}
                    >
                      Contact Us
                    </span>
                  </li>
                )}
                {blog_page && (
                  <li className={`nav-item`}>
                    <span
                      className="nav-link"
                      onClick={() => handleClick(blog_page)}
                    >
                      Blog
                    </span>
                  </li>
                )}
                {survey_page.heading &&
                  (survey_page.page_title === "azx3layout" ||
                    survey_page.page_title === "azx4layout" ) && (
                    <li className={`nav-item navPhone`}>
                      <a
                        className="nav-link"
                        href={`tel:${survey_page.heading}`}
                      >
                        <img src={PhoneIcon} alt="hone Icon"></img>
                        {survey_page.heading}
                      </a>
                    </li>
                  )}
              </ul>
            </div>
            {(window.location.host=== "bizau.businessfundstoday.net" ||
              window.location.host === "www.bizau.businessfundstoday.net" )&&
              <div className="secure-ssl">
                <img className="secure-ssl-img" src="https://s3-ap-southeast-2.amazonaws.com/micrositesacquirely/micrositessub/acquirely_80_b0b7da17-6526-4a95-8d16-c73f6af5da4f.png"/>
              </div>
            }
            {(window.location.host=== "v2.mysuperannuationcompare.com.au" ||
              window.location.host === "www.v2.mysuperannuationcompare.com.au" )&&
              <div className="secure-ssl">
                <img className="secure-ssl-img" src="https://micrositesacquirely.s3.ap-southeast-2.amazonaws.com/micrositessub/Safe-And-Secure.png"/>
              </div>
            }
            {(window.location.host=== "loan.financesolver.co" ||
              window.location.host === "www.loan.financesolver.co" )&&
              window.location.pathname=== "/carloan/comparenow"
              &&
              <div className="-ssl">
                <img className="secure-ssl-img" src="https://s3-ap-southeast-2.amazonaws.com/micrositesacquirely/micrositessub/acquirely_26_478dd89d-f8f6-425a-9f95-9f19e676f620.png"/>
              </div>
            }
            
          </div>
        </nav>

        {siteDataValue ? (
          <div>
            {siteDataValue.page_id === 1 && page !== 7 ? (
              <div
                className="template-2"
                style={{
                  backgroundColor: siteData.secondary_color,
                  display: pageType === "survey" && "none",
                }}
              ></div>
            ) : page !== 7 ? (
              <div
                className="t-2-inner-header"
                style={{ backgroundColor: siteData.secondary_color }}
              >
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <h4>{siteDataValue.page_title}</h4>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </header>
    </div>
  );
};

HeaderPage.propTypes = {
  siteData: object,
  page: number,
};

HeaderPage.defaultProp = {
  page: 1,
};

export default HeaderPage;
